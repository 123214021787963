/* RESPONSIBLE TEAM: team-ai-agent */

import { Resource } from 'ember-resources/core';
import { type Named } from 'ember-resources/core/types';
import { inject as service } from '@ember/service';
import { taskFor } from 'ember-concurrency-ts';
import type IntlService from 'ember-intl/services/intl';
import {
  type PromptPrefix,
  blocksToText,
  imageBlockMapping,
  blocksToTextWithImages,
  getOpenAIPrompt,
  textToBlocks,
  calculateBlockListLength,
} from 'embercom/lib/open-ai-prompt';
import { captureException } from 'embercom/lib/sentry';
import type InboxApi from 'embercom/services/inbox-api';
import { registerDestructor } from '@ember/destroyable';
import { type BlockList } from '@intercom/interblocks.ts';
import { dropTask } from 'ember-concurrency-decorators';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { assertExists } from 'embercom/lib/assertions';
import { type ComposerPublicAPI } from '@intercom/embercom-prosemirror-composer';
import { type TaskArgs } from '@glint/environment-ember-loose/registry';
import { scheduleOnce } from '@ember/runloop';
import type Session from 'embercom/services/session';
import type CopilotApi from 'embercom/services/copilot-api';

interface AiAssistApiArgs {
  conversationId: number;
  isTicket?: boolean;
  currentBlocks?: BlockList;
  composerApi?: ComposerPublicAPI;
}

export type AiAssistPromptKey = PromptPrefix | 'tone';

export default class AiAssistApi extends Resource<Named<AiAssistApiArgs>> {
  @service declare intl: IntlService;
  @service declare inboxApi: InboxApi;
  @service declare copilotApi: CopilotApi;
  @service declare intercomEventService: any;
  @service declare notificationsService: any;
  @service declare session: Session;

  private lastConversationId?: number;
  private composerApi?: ComposerPublicAPI;
  private currentBlocks: BlockList = [];

  // lastBlocksList stashes the entire composer document at a point in time so
  // that we can undo to that exact point later.
  private lastBlocksList: BlockList = [];

  // lastCompletionArgs stashes the last arguments to the completionTask, so
  // we can run completion with the same arguments again.
  private lastCompletionArgs?: TaskArgs<AiAssistApi['completionTask']>;

  // selectionStart stores the start of a selection that was transformed.
  private selectionStart?: number;

  // selectionLength stores the length of the transformed string. It's used to
  // figure out the exact transformation to replace when running redo.
  private selectionLength?: number;

  @tracked private _isActionsToolbarVisible = false;

  // This state is to make sure we don't teardown the toolbar
  // when we're retrying the completion task.
  @tracked private _isRetrying = false;

  constructor(owner: unknown) {
    super(owner);
    registerDestructor(this, this.teardown);
  }

  modify(_: never, args: AiAssistApiArgs) {
    // When the conversation changes, cancel any running tasks and reset local state
    if (this.lastConversationId !== args.conversationId) {
      this.teardown();
    }

    this.lastConversationId = args.conversationId;
    this.composerApi = args.composerApi;
    this.currentBlocks = args.currentBlocks ? [...args.currentBlocks] : [];
  }

  get isWorking() {
    return (
      taskFor(this.completionTask).isRunning ||
      taskFor(this.generateSummaryTask).isRunning ||
      taskFor(this.generateSummaryForExternalConversationTask).isRunning ||
      this._isRetrying
    );
  }

  get isActionsToolbarVisible() {
    return this._isActionsToolbarVisible;
  }

  @action onHideToolbar() {
    this._isActionsToolbarVisible = false;

    this.composerApi?.composer.enableFormatting();
    this.teardown();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'dismissed',
      object: 'ai_assist_toolbar',
      conversation_id: this.lastConversationId,
    });
  }

  @action onShowToolbar() {
    this._isActionsToolbarVisible = true;
    this.composerApi?.composer.disableFormatting();

    this.intercomEventService.trackAnalyticsEvent({
      action: 'viewed',
      object: 'ai_assist_toolbar',
      conversation_id: this.lastConversationId,
    });
  }

  @action undo() {
    if (!this.lastBlocksList) {
      throw new Error('Trying to undo without history');
    }

    assertExists(this.composerApi);
    this.composerApi.composer.commands.replaceAllWithBlocks(this.lastBlocksList);
    this.onHideToolbar();
  }

  @action async tryAgain() {
    assertExists(this.composerApi);

    this._isRetrying = true;
    this.selectAndFocus(); // reselect content to transform

    await this.composerApi.composer.commands.transformSelection(() => {
      if (!this.lastCompletionArgs) {
        return Promise.resolve([]);
      }

      let [promptKey, conversationId, blocks, analyticsMetadata, userId] = this.lastCompletionArgs;
      return this.complete(promptKey, conversationId, blocks, analyticsMetadata, userId, {
        isRetrying: true,
      });
    });
  }

  @action async complete(...args: TaskArgs<AiAssistApi['completionTask']>) {
    let blocks = await taskFor(this.completionTask).perform(...args);

    let afterRender = () => {
      this.selectAndFocus();
      this.onShowToolbar();
      this._isRetrying = false;
    };

    scheduleOnce('afterRender', this, afterRender);

    return blocks;
  }

  @action generateSummary(...args: TaskArgs<AiAssistApi['generateSummaryTask']>) {
    return taskFor(this.generateSummaryTask).perform(...args);
  }

  @action generateSummaryForExternalConversation(
    ...args: TaskArgs<AiAssistApi['generateSummaryForExternalConversationTask']>
  ) {
    return taskFor(this.generateSummaryForExternalConversationTask).perform(...args);
  }

  // Private API

  @action private teardown() {
    this.lastBlocksList = [];
    this.selectionStart = undefined;
    this.selectionLength = undefined;
    this.lastCompletionArgs = undefined;

    taskFor(this.completionTask).cancelAll();
    taskFor(this.generateSummaryTask).cancelAll();
    taskFor(this.generateSummaryForExternalConversationTask).cancelAll();
  }

  @dropTask
  private *completionTask(
    promptKey: AiAssistPromptKey,
    conversationId: number | undefined,
    blocks: BlockList,
    analyticsMetadata: Record<string, unknown>,
    userId?: string,
    opts: { isRetrying: boolean } = { isRetrying: false },
  ) {
    let currentBlocks = [...this.currentBlocks];

    this.lastCompletionArgs = [promptKey, conversationId, blocks, analyticsMetadata, userId, opts];
    this.selectionStart = this.composerState.from;

    // if the user hits cmd+A to select all text, selectionStart is 0 (which should be impossible –
    // 1 is the starting index).
    if (this.selectionStart === 0) {
      this.selectionStart = 1;
    }

    let completionText: string | undefined;
    let requestId;

    let blocksHaveImages = blocks.some((block) => block.type === 'image');

    try {
      if (promptKey === 'tone') {
        let completionResponse = (yield this.inboxApi.fetchToneCompletion(
          blocksHaveImages ? blocksToTextWithImages(blocks) : blocksToText(blocks),
          conversationId,
          blocksHaveImages,
          userId,
        )) as Awaited<ReturnType<InboxApi['fetchToneCompletion']>>;
        if (completionResponse.success) {
          completionText = completionResponse.completionText;
          requestId = completionResponse.requestId;
        } else {
          throw new Error(`Tone completion failed: ${completionResponse.failureReason}`);
        }
      } else {
        let prompt_data = getOpenAIPrompt(promptKey, blocks, blocksHaveImages);
        ({ completionText, requestId } = yield this.inboxApi.fetchOpenAICompletion(
          prompt_data,
          conversationId,
          blocksHaveImages,
        ));
      }
    } catch (err) {
      captureException(err, {
        tags: { aiAssist: 'completionTask' },
        extra: { promptKey, keepImages: blocksHaveImages, isRetrying: opts.isRetrying },
      });

      let status = err.response?.status;

      if (status === 422) {
        this.notificationsService.notifyError(
          this.intl.t('inbox.notifications.completion-failed-too-long'),
        );
      } else if (status === 403) {
        this.notificationsService.notifyError(
          this.intl.t('inbox.notifications.completion-failed-forbidden'),
        );
      } else {
        this.notificationsService.notifyError(this.intl.t('inbox.notifications.completion-failed'));
      }

      throw Promise.reject(new Error('OpenAI completion failed'));
    }

    this.intercomEventService.trackAnalyticsEvent({
      prompt_key: promptKey,
      request_id: requestId,
      conversation_id: conversationId,
      is_retry: opts.isRetrying,
      contains_images: blocksHaveImages,
      ...analyticsMetadata,
    });

    let completionBlocks: BlockList = [];
    if (completionText) {
      completionBlocks = textToBlocks(completionText, imageBlockMapping(blocks));
      this.selectionLength = calculateBlockListLength(completionBlocks);
    }

    if (currentBlocks && !opts.isRetrying) {
      this.lastBlocksList = currentBlocks;
    }

    return completionBlocks;
  }

  @dropTask
  private *generateSummaryTask(conversationId: number, isTicket = false) {
    try {
      return (yield this.inboxApi.generateSummaryForConversation(conversationId)) as Awaited<
        ReturnType<InboxApi['generateSummaryForConversation']>
      >;
    } catch (err) {
      let type = isTicket ? 'ticket' : 'conversation';
      this.notificationsService.notifyError(
        err.response?.status === 422
          ? this.intl.t(`inbox.notifications.summarization-failed-too-long-${type}`)
          : this.intl.t(`inbox.notifications.summarization-failed-${type}`),
      );
      if (!err.response) {
        captureException(err);
      }
    }

    return;
  }

  @dropTask
  private *generateSummaryForExternalConversationTask() {
    return (yield this.copilotApi.generateSummaryForExternalConversation()) as Awaited<
      ReturnType<CopilotApi['generateSummaryForExternalConversation']>
    >;
  }

  private get composerState() {
    assertExists(this.composerApi);
    return this.composerApi.composer.state.prosemirrorState.selection;
  }

  private selectAndFocus() {
    assertExists(this.selectionStart);
    assertExists(this.selectionLength);

    this.composerApi?.composer.commands.setSelection(
      this.selectionStart,
      this.selectionStart + this.selectionLength,
    );

    this.composerApi?.composer.commands.focus();
  }
}
